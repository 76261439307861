import React from "react"
import styled from "styled-components";
import constant from '../constant';
import Layout from "../components/layout";
import _ from 'lodash';
import SEO from "../components/seo";
import withI18next from '../components/withI18next';

const PHeader = styled.header.attrs({ id: 'product-header' })`
  margin: 0 auto;
  margin-top: -80px;
  max-width: 1920px;
  //min-width: 1080px;
  //height: 280px;
  background:linear-gradient(90deg,rgba(134,166,255,1) 0%,rgba(112,139,255,1) 100%);
  padding-top: 176px;
  /* padding-top: 8%; */
  padding-bottom: 2%;
  background: linear-gradient(to bottom, #708BFF, #8BA1FF);
  canvas {
    position: absolute;
    top: 0;
    bottom: 0;
  }
  h1 {
    font-size: 34px;
    @media (max-width: 750px) {
      //background: palevioletred;
      font-size: 24px;
    }
  }
  p {
    font-size: 14px;
    @media (max-width: 750px) {
      //background: palevioletred;
      font-size: 12px;
    }
  }
`;

const IContent = styled.div`
  margin: 0 auto;
  //min-width: 1080px;
  .sub-text {
    color: #999;
    font-size: 12px;
  }
  div:nth-child(2n) {
    background: ${constant.productBg};
  }
  .state {
    .game-select {
      max-width: 578px;
      height: 260px;
      margin: 0 auto;
      .game-item {
        height: 126px;
        width: 126px;
        background: center/83px no-repeat;
        border: 2px solid ${constant.logoBorder};
        border-radius: 50%;
        cursor: pointer;
        opacity: 0.5;
        &.active {
          box-shadow: 0 0 20px 0px rgba(185, 201, 255, 1);
          opacity: 1;
        }
      }
      @media (max-width: 750px) {
        height: 130px;
        justify-content: space-around !important;
        .game-item {
          height: 63px;
          width: 63px;
          -webkit-background-size: 50px 50px;background-size: 50px 50px;
        }
      }
    }
  }
  .product-zh-part {
    padding-top: 35px;
    padding-bottom: 35px;
    max-width: 1920px;
    min-width: 100%;
    margin: 0 auto;
    h2 {
      //max-width: 1620px;
      width: 90%;
      max-width: 1200px;
      margin: 0 auto;
      font-size: 34px;
      margin-bottom: 34px;
      .sub-title {
        vertical-align: middle;
        color: #8992BF;
        font-size: 24px;
      }
    }
    .list-group {
      &.zh {
        max-width: 1200px;
        width: 97%;
      }
      margin: 0 auto;
      @media (max-width: 750px) {
        justify-content: center !important;
      }
    }
    .feature-zh-card {
      position: relative;
      width: 280px;
      height: 320px;
      padding: 0px 12px;
      margin:0 10px 30px;
      //margin-bottom: 30px;
      border: none;
      /* border-image-slice: 100; */
      /* border-top: 4px solid; */
      border-radius: 4px;
      box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
      transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 0 0 2px transparent, 0px 9px 10px 0px rgba(0,0,0,0.1);
      }
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: " ";
        width: 100%;
        height: 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:nth-child(1)::after {     
        background: linear-gradient(90deg, rgba(94,200,178,1) 0%,rgba(77,187,157,1) 100%);
      }
      &:nth-child(2)::after {
        background: linear-gradient(90deg,rgba(136,90,247,1) 0%,rgba(104,72,234,1) 100%);
      }
      &:nth-child(3)::after {
        background: linear-gradient(90deg,rgba(102,193,251,1) 0%,rgba(96,173,251,1) 100%);
      }
      &:nth-child(4)::after {
        background: linear-gradient(90deg,rgba(80,163,248,1) 0%,rgba(59,124,241,1) 100%);
      }
      .feature-container {
        height: 220px;
        li {
          font-size: 14px;
          max-width: 128px;
          display: inherit;
        }
      }
      li::before {
        content: "●";
        color: #A4ABCC;
        vertical-align: text-bottom;
        margin-right: 10px;
        font-size: 12px;
      }
      h3 {
        height: 80px;
        line-height: 80px;
        font-size: 20px;
        vertical-align: top;
        border-bottom: 1px solid #eee;
        color: ${constant.subTitle};
        /* .sub-title {
          font-size:14px;
          color:rgba(137,146,191,1);
          line-height:20px;
        } */
        img {
          vertical-align: text-top;
          margin-right: 5px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .product-en-part {
    max-width: 1920px;
    min-width: 100%;
    margin: auto;
    border: .1px solid transparent;
    h2 {
      max-width: 1620px;
      min-width: 90%;
      margin: 30px auto;
      margin-bottom: 10px;
      text-align: left !important;
      font-size: 34px;
      font-weight: 600;
      color: ${constant.active};
      .sub-title {
        font-size: 24px;
        text-align: text-bottom;
        color: #8992BF;
      }
      @media(max-width: 750px) {
        font-size: 24px;
        padding-left: 5px;
      }
    }
    .list-group {
      margin: auto;
      max-width: 1620px;
      //min-width: 1180px;
      @media(max-width: 750px) {
        justify-content: center !important;
      }
    }
    .feature-en-card {
      margin-bottom: 50px;
      border: none;
      flex: 0 0 405px;
      background: transparent;
      @media(max-width: 750px) {
        flex: 0 0 90%
        //width: 90%;
      }
      h3 {
        position: relative;
        font-size: 20px;
        text-align: left !important;
        line-height: 60px;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 230px;
          border-bottom: 1px solid #eee;
        }
        img {
          vertical-align: text-top;
          margin-right: 10px;
        }
      }
      li {
        font-size: 14px;
      }
      li::before {
        content: "●";
        color: #A4ABCC;
        vertical-align: text-bottom;
        margin-right: 10px;
        font-size: 12px;
      }
    }
  }
  .league-list {
    h2 {
      font-size: 14px;
    }
    > p {
      font-size: 14px;
      color: ${constant.active};
    }
    .league-display {
      .league-item {
        flex: 0 0 100px;
        //margin-right: 20px;
        //margin-bottom: 20px;
        margin: 0 10px 20px;
        font-size: 14px;
        p { width: 100px; }
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 70px;
          height: 70px;
          object-fit: contain;
        }
      }
    }
  }
`;

const Product = ({ t, locale, location }) => {
  const { hash } = location;
  const cur = Number(hash.slice(1));
  const [type, changeType] = React.useState(cur || 0);
  const [ex, expand] = React.useState(false);
  const types = ['dota2', 'lol', 'csgo', 'aov'];
  const dataType = ['base', 'statistic', 'real_time', 'custom', 'no_delay'];
  const dataIcon = ['base', 'stat', 'real', 'custom', 'live'];
  const game = types[type];
  const viewSet = t('product', { returnObjects: true });
  return (
    <Layout type="product">
      <SEO title="Product" />
      <PHeader>
        <h1 className="text-center text-white">{viewSet.title}</h1>
        <p className="text-center text-white">{viewSet.subTitle}</p>
      </PHeader>
      <IContent>
        <div className="state">
          <ul className="game-select list-group list-group-horizontal align-items-center justify-content-between">
            {constant.Logo.map((logo, idx) =>
              <li
                className={`game-item ${type === idx ? 'active' : ''}`}
                style={{ backgroundImage: `url(${logo})` }}
                onClick={() => changeType(idx)}
              />
            )}
          </ul>
        </div>
        <div className="league-list product-zh-part">
          <h2 className="text-center">{viewSet.league_tip}</h2>
          <ul className="league-display list-group list-group-horizontal flex-wrap zh">
            {ex ?
              viewSet[game].map(league => (
                <li className="league-item text-center">
                  <img src={league.path} alt="" />
                  <p title={league.name} className="text-truncate">{league.name}</p>
                </li>)
              ) :
              viewSet[game].slice(0, 10).map(league => (
                <li className="league-item text-center">
                  <img src={league.path} alt="" />
                  <p title={league.name} className="text-truncate">{league.name}</p>
                </li>)
              )
            }
          </ul>
          <p className="text-center pointer" onClick={() => expand(!ex)}>{viewSet.view_all[ex ? 1 : 0]}</p>
        </div>
        {
          dataType.map((type, index) => {
            if (_.get(viewSet, `${type}.${game}`, '')) {
              return (
                <div className={`league-info product-${locale}-part`}>
                  <h2>{_.get(viewSet, `${type}.${game}.title`, '')} <span className="sub-title">{_.get(viewSet, `${type}.${game}.subTitle`, '')}</span></h2>
                  <ul className={`list-group list-group-horizontal ${locale} flex-wrap justify-content-start`}>
                    {_.get(viewSet, `${type}.${game}.content`, []).map((info, idx) => (
                      <li className={`feature-${locale}-card card`} key={info.name}>
                        <h3 className="text-center">
                          <img src={constant[dataIcon[index]][idx]} alt=" " />
                          {info.name}
                          {info.subTitle &&
                            <React.Fragment>
                              <br />
                              <span className="sub-title">{info.subTitle}</span>
                            </React.Fragment>}
                        </h3>
                        <ul className="feature-container d-flex flex-column flex-wrap">
                          {info.feature.map(f => <li key={f.subText ? f.text : f}>
                            {
                              f.subText ? <React.Fragment>{f.text} <span className="sub-text">{f.subText}</span></React.Fragment> : f
                            }</li>)}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            }
          })
        }
      </IContent>
    </Layout>
  );
}

export default withI18next({ ns: 'common' })(Product);